//
// Custom font (e.g. for inserting icomoon font)
// --------------------------------------------------

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?b79p9m');
  src:  url('../fonts/icomoon.eot?b79p9m#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?b79p9m') format('truetype'),
  url('../fonts/icomoon.woff?b79p9m') format('woff'),
  url('../fonts/icomoon.svg?b79p9m#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\61";
}
.icon-circle:before {
  content: "\63";
}
.icon-search:before {
  content: "\73";
}
