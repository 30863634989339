/* ==========================================================================
   basic styles für content area and backend wysiwyg
   ========================================================================== */

.no-display {
  display: none!important;
}

.alert-header {
  font-weight: bold;
}

#content,
.mce-content-body {
  font-family: Arial;
  line-height: 24px;
}

#content img,
.mce-content-body img {
  max-width: 100%;
}

#content p,
.mce-content-body p {
  margin-bottom: 20px;
}

#content a,
.mce-content-body a {
  color: $brand-primary;
}

.mce-content-body {
  padding: 10px!important;
}

.-webcms-component > ul,
.product-text > ul,
.mce-content-body > ul {
  margin-bottom: 20px!important;
  list-style-type: none;
  padding: 0;
}

.-webcms-component > ul > li,
.product-text > ul > li,
.mce-content-body > ul > li {
  position: relative;
  padding: 0 0 0 15px;
  //margin: 0 0 5px 0;
  //Square
  &:before {
    content: '';
    background-color: $brand-primary;
    display: block;
    width: 6px;
    height: 6px;
    margin-left: -15px;
    position: absolute;
    top: 10px;
  }
  //Arrow
  /*&:before {
    content: '';
    border-style: solid;
    border-width: 6px 0 6px 9px;
    border-color: transparent transparent transparent $brand-primary;
    position: absolute;
    left: 0;
    top: 5px;
  }*/
}

#captcha {
  background-image: url("../../captcha.php");
  width: 100px;
}

.pdflink,
.doclink,
.pptlink,
.xlslink,
.jpglink {
  padding-left: 25px;
  padding-top: 1px;
  background-repeat: no-repeat;
  margin-bottom: 5px;
}

.pdflink {
  background-image: url('../../images/download_icons/pdf_files.gif');
}

.doclink {
  background-image: url('../../images/download_icons/word_files.gif');
}

.pptlink {
  background-image: url('../../images/download_icons/power_files.gif');
}

.xlslink {
  background-image: url('../../images/download_icons/excel_files.gif');
}

.jpglink {
  background-image: url('../../images/download_icons/jpg_files.gif');
}