// Custom (non-bootsrap) Project Styles - must be imported first in case of SASS
@import "fancybox/jquery.fancybox.scss";
@import "connectiv/z-index.scss";
@import "connectiv/sprite.scss";
@import "connectiv/custom-font.scss";
@import "connectiv/custom-vars.scss";
@import "connectiv/conn-basics.scss";

// Font-Awesome
@import "font-awesome/font-awesome.scss";

//Slick Slider
@import "slick/slick.scss";
@import "slick/slick-theme.scss";

//Bootstrap
//enthält in dieser Einstellung nur häufig benötigte Dateien (bei Bedarf zusätzliche Kompontenten in der _bootstrap.scss einkommentieren)
@import "bootstrap/bootstrap";

//Sonstiges
@import "connectiv/custom-mobile-nav.scss";

/* ==========================================================================
   Project's custom styles
   ========================================================================== */

/*
[15:32:07] marcusnowak: Sans Encode Condensed ;)
[15:32:31 | Bearbeitet 15:30:04] Thorsten Welke: is überall die Schriftart?
[15:33:18] marcusnowak: Jou, Extrabold und Medium/Regular wurden genutzt, für Fließtext normales Arial


Topbar: #767676
Strich unterhalb Navi: #e5e5e5
Grün: #4d9224
Grau (Button, Teaserhintergrund, etc): #767676 test

Startseitenslider: Grünes Kästchen auf 90% Transparenz vom oberen Grün - rgba(77,146,36,0.9)
Bulletpoints (aktiv ist grün) inaktiv: rgba(180,180,180,0.5)

Folgeseite Hintergrund Seitennavigation: #e5e5e5
*/

body {
  font-family: 'Encode Sans Condensed', sans-serif;
  background-color: #fff;
}

.image-left {
  display: block;
  margin: 0 auto;
}

.btn {
  line-height: 1.42857143; //fixes SASS number precision issue
}

a {
  color: #000;
}

.list-group a > p {
  color: #000!important;
}

.container-fluid {
  max-width: 1000px + $grid-gutter-width;
}

h1, h2, h3, h4 {
  font-family: 'Encode Sans Condensed', sans-serif;
  margin-top: 0;
}

h1 {
  // text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
}

h2 {
  font-size: 18px;
  border-bottom: 2px solid $brand-primary;
  padding-bottom: 12px;
  margin-bottom: 20px;
}

h3 {
  font-size: 16px;
  font-weight: 700;
}

.top-bar {
  background-color: #767676;
  height: 35px;
  padding-top: 7px;
  color: #fff;
}

.lang-switch {
  float: right;
}

.lang-switch a {
  color: #fff;
  text-transform: uppercase;
  float: left;
}

.lang-switch a:not(:last-child):after {
  content: '|';
  display: inline-block;
  margin: 0 5px;
}

.lang-switch > span {
  font-weight: 700;
  color: #fff;
  margin-right: 10px;
  float: left;
}

.lang-switch > a.active {
  font-weight: 700;
}

.logo {
  margin-top: -13px;
  display: block;
  margin-left: -15px;
  transition: all 0.3s;
}

.themepic-slider {
  background-image: url(../img/bg_pattern_headerslider.png);
  background-repeat: repeat;
  background-position: center;
  padding-top: 40px;
  padding-bottom: 80px;
  box-shadow: inset 1px 3px 9px -6px;
  margin-bottom: 30px!important;
  font-family: 'Encode Sans Condensed', sans-serif!important;
}

.themepic-slider__item {
  position: relative;
  outline: none!important;
}

.themepic-slider__item-image {
   height: 400px;
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}

.slick-dots {
  bottom: 20px;
}

.overlay-outer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
  //float: right;
  //max-width: 400px;
  //margin-top: -144px;
  opacity: 0;
  transition: opacity 0.5s 0.5s;
}

.themepic-slider__overlay {
  //float: right;
  //background-color: $brand-primary;
  color: rgba(77,146,36,1);
  //color: #fff;
  //padding: 25px 20px 35px 20px;
  //margin-bottom: 18px;
}

.themepic-slider__overlay-title {
  display: block;
  font-size: 24px;
  line-height: 18px;
  font-weight: 700;
  //text-transform: uppercase;
  margin-bottom: 10px;

}

.slick-active .overlay-outer {
  opacity: 1;
}

.themepic-slider__overlay-text {
  font-size: 18px;
  line-height: 18px;
}

.themepic-slider__btn {
  display: block;
  min-width: 180px;
  color: #fff!important;
  padding: 10px 20px;
  background-color: $dark-grey;
  float: right;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
}

.themepic-slider__btn {
  &:hover, &:focus, &:active {
    color: #fff;
  }
}

.infoboxes h1 {
  text-align: center;
  text-transform: none;
  margin-bottom: 50px;
  font-size: 20px;
}

.teaser-start {
  display: block;
  //height: 270px;
  color: #333!important;
  font-size: 20px;
  margin-bottom: 30px;
  font-family: 'Encode Sans Condensed', sans-serif!important;
  &:hover, &:focus, &:active {
    color: #fff;
  }
}

.teaser-start__img {
  height: 114px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.teaser-start__labeling {
  //padding: 20px 15px 15px 15px;
  font-size: 18px;
  text-align: center;
  margin: 20px 0 50px 0;
  line-height: 28px;
}

.teaser-start__labeling:not(.teaser-start__labeling--green) {
  //background-color: $dark-grey;
}

.teaser-start__labeling--green {
  background-color: $brand-primary;
}

.teaser-start__title {
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}

footer {
  background-color: $brand-primary;
}

footer a {
  color: #fff;
  &:hover, &:focus, &:active {
    color: #fff;
  }
}

.footer-logo {
  float: left;
  //margin-right: 25px;
}

.copyright {
  color: #fff;
  float: left;
  width: 100%;
}

.copyright div {
  margin-right: 20px;
}

.copyright span {
  display: block;
}

.footer-nav {
  float: right;
}

footer {
  padding-top: 20px;
}

.pathfinder {
  margin-bottom: 25px;
  font-family: Arial;
  font-size: 12px;
}

.pathfinder a {
  float: left;
  &.active {
    color: $brand-primary;
  }
}

.pathfinder a:not(:last-child):after {
  content: '/';
  margin: 0 5px;
  display: inline-block;
}

.product-head {
  height: 270px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 40px;
}

.button {
  font-family: 'Encode Sans Condensed', sans-serif;
  display: inline-block;
  background-color: $brand-primary;
  color: #fff!important;
  padding: 12px 35px 12px 25px;
  font-size: 16px;
  position: relative;
  margin-bottom: 20px;
  border: none;
  min-width: 200px;
  &:after {
    content:'';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 25px 48px 0;
    border-color: transparent #fff transparent transparent;
    position: absolute;
    right:0;
    top:0;
  }
  &:hover, &:active, &:focus {
    color: #fff;
  }
}

.button--request {
  margin-top: 227px;
  float: right;
}

.info {
  margin-bottom: 40px;
}

.info__heading {
  font-weight: bold;
  margin-bottom: 20px;
  display: inline-block;
}

.info__box {
  display: flex;
  margin-bottom: 40px;
}


.info__box-minidesc {
  background-color: $light-grey;
  padding: 15px 15px 20px 15px;
  min-height: 120px;
  > p {
    margin-bottom: 0!important;
  }
}

.info__box-left {
  width: 65%;
  float: left;
}

.info__box-right {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 35%;
  float: left;
  min-height: 120px;
}

.teaser-left {
  display: block;
}

.teaser-left__image {
  height: 165px;
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
}

.teaser-left__labeling {
  min-height: 95px;
  background-color: $dark-grey;
  color: #fff;
  padding: 20px 15px 20px 20px;
  font-size: 18px;
}

.teaser-left__title {
  font-weight: 700;
  text-transform: uppercase;
  display: block;
}

.teaser-left__text i {
  display: inline-block;
  margin-left: 10px;
  top: 2px;
  position: relative;
}

.footer-nav a {
  padding-left: 5px;
}

.footer-nav a:not(:last-child) {
  padding-right: 5px;
}

.gallery-slider__main {
  margin-bottom: 20px;
}

.gallery-slider__main .gallery-slider--item {
  background-color: $light-grey;
}

.gallery-slider__main p {
  padding: 15px 0!important;
  margin: 0!important;
  line-height: 14px;
}

.gallery-slider__nav {
  margin: 10px 0;
}

.gallery-slider__nav {
  .slick-prev:before, .slick-next:before {
    font-family: icomoon!important;
    content: "\61";
    font-weight: 400;
    color: $dark-grey;
  }

  .slick-prev {
    transform: rotateY(180deg);
    top: 45%;
  }

  .slick-dots {
    bottom: -40px;
  }
}

.gallery {
  margin-bottom: 60px;
}

.gallery-slider--item__img-big {
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.gallery-slider--item__img-small {
  position: relative;
  height: 120px;
  margin: 0 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.gallery-slider--item__img-small .img-title {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 2px 10px;
  background: #e5e5e5;
  font-size: 12px;
  line-height: 16px;
}

.agency-item {
  background: #e5e5e5;
  padding: 10px;
  margin-bottom: 20px;
  transition: all 0.3s;
  width: 100%;
}
/*
.agency-item.active {
  background-color: $brand-primary;
  color: #FFF;
}

.agency-item:hover {
  background-color: $brand-primary;
  color: #FFF;
}
*/
.job-preview-wrapper {
  background-color: $light-grey;
  padding: 10px;
  margin-bottom: 10px;
}

.job-preview-img {
  height: 250px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.job-preview-content h2 {
  margin-top: 10px;
}

.job-preview-content a {
  margin-bottom: 0;
}

.job-preview-content a::after {
  border-color: transparent $light-grey transparent transparent;
}

.copyright div:first-of-type {
  margin-bottom: 10px;
}

.copyright div:last-of-type {
  margin-top: 10px;
}

.map-wrapper {
  margin: 20px 0;
}

.zusatzkomponenten {
  background-color: #e5e5e5;
  width: 100%;
  margin-bottom: 15px;
  padding-bottom: 1px;
}

.endprodukt {
  margin-bottom: 25px;
}

.endprodukt__image {
  position: relative;
  height: 250px;
  overflow: hidden;
}

.endprodukt__image a {
  display: block;
  position: relative;
  height: 250px;
}

.endprodukt__image img {
  display: block;
  position: relative;
  min-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}


.endprodukt__image a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.3s;
  z-index: 1;
}

.endprodukt__image a:after {
  font-family: icomoon;
  content: "\73";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  color: #FFF;
  font-size: 20px;
  transition: font-size 0.3s;
  z-index: 1;
}

.endprodukt__image a:hover:after {
  font-size: 30px;
}

.endprodukt__title,
.zusatzkomponenten__title {
  background-color: $brand-primary;
  color: #FFF;
  width: 100%;
  padding: 3px 10px;
  font-weight: bold;
}

.zusatzkomponenten ul {
  margin: 10px;
  padding: 0;
  list-style: none;
}

.zusatzkomponenten ul li {
  position: relative;
  margin: 0 0 8px 0;
  padding: 0 0 0 20px;
}

.zusatzkomponenten ul li:before {
  position: absolute;
  font-family: icomoon!important;
  content: "\61";
  color: $brand-primary;
  margin-right: 8px;
  font-size: 12px;
  left: 0;
}

@media (max-width: $screen-xs-max) {

  .logo {
    width: 170px;
    float: left;
  }

  .search-input {
    position: absolute;
    top: 9px;
    background-color: transparent;
    border: none;
    outline: none;
    margin-left: 30px;
  }

  .themepic-slider {
    padding-bottom: 60px;
  }

  .themepic-slider__item-image {
    background-size: contain;
    height: 300px;
    background-position: top;
  }

  .product-head {
    height: 200px;
  }

  .button--request {
    margin-top: 160px;
  }

  .copyright {
    margin-top: 30px;
  }

  footer {
    padding-bottom: 15px;
  }

  .footer-nav {
    margin-top: 20px;
  }

  .pathfinder {
    margin-top: 20px;
  }

}

@media (min-width: $screen-tablet) and (max-width: $screen-sm-max) {
  //.logo {
  //  width: 170px;
  //}
  .search-input {
    width: 115px!important;
    left: -118px!important;
  }
}


/* Kleine Geräte (Tablets, 768px und breiter) */
@media (min-width: $screen-tablet) {

  #earth_map {
    width: 100%;
    max-width: 100%;
  }

  .endprodukt__image {
    height: 225px;
  }

  .endprodukt__image a {
    height: 225px;
  }

    /*
    #USA, #Frankreich, #Deutschland, #Italien, #Japan {
      position: relative;
      transition: all 0.3s;
    }

    #USA:hover, #Frankreich:hover, #Deutschland:hover, #Italien:hover, #Japan:hover,
    #USA.active, #Frankreich.active, #Deutschland.active, #Italien.active, #Japan.active {
      cursor: pointer;
      stroke: $brand-primary;
      stroke-width: 10;
      fill: #FFF;
    }
    */

  /*
  .deutschland:hover, .italien:hover, .usa:hover, .japan:hover,
  .deutschland.active, .italien.active, .usa.active, .japan.active {
    cursor: pointer;
    z-index: 100;

    button {
      margin: -3px -3px 0 0;
      width: 22px;
      height: 22px;
      border: 1px solid $brand-primary;
      background-color: #fff;
    }

    .popup-item {
      display: block;
      background-color: $brand-primary;
      color: #fff;
      padding: 10px;
    }
  }

  button.agency-item {
    padding: 0;
    width: 16.4px;
    height: 16.4px;
    border: 1px solid #FFF;
    border-radius: 100%;
    background-color: $brand-primary;
    transition: all 0.3s;
  }

  .agency-item-wrapper .popup-item {
    display: none;
  }

  .agency-item-wrapper.deutschland {
    position: absolute;
    top: 19.9%;
    left: 47.7%;
  }

  .agency-item-wrapper.italien {
    position: absolute;
    top: 26.2%;
    left: 48.5%;
  }

  .agency-item-wrapper.usa {
    position: absolute;
    top: 27.3%;
    left: 15%;
  }

  .agency-item-wrapper.japan {
    position: absolute;
    top: 26.8%;
    left: 84.8%;

    .popup-item {
      margin-left: -55px;
    }
  }
*/

  #USA, #Frankreich, #Deutschland, #Italien, #Japan {
    position: relative;
    transition: all 0.3s;
  }

  #USA:hover, #Frankreich:hover, #Deutschland:hover, #Italien:hover, #Japan:hover,
  #USA.active, #Frankreich.active, #Deutschland.active, #Italien.active, #Japan.active {
    cursor: pointer;
    stroke: $brand-primary;
    stroke-width: 10;
    fill: #FFF;
  }

  .map-wrapper {
    position: relative;
    margin: 30px 0;
  }

  .agency-item-wrapper {
    position: absolute;
  }

  .agency-item-wrapper:hover {
    z-index: 10;
  }

  .agency-item-wrapper button {
    width: 20px;
    height: 20px;
    background-color: $brand-primary;
    border-radius: 100%;
    border: 1px solid #FFF;
    padding: 0;
    margin: 0;
    transition: all 0.3s;
  }

  .agency-item-wrapper.active {
    z-index: 10;
  }

  .agency-item-wrapper.active button {
    border: 3px solid #FFF;
    background: $brand-primary;
    box-shadow: 0px 0px 0px 5px $brand-primary;
  }

  .agency-item-wrapper .popup-item {
    white-space: nowrap;
    position: absolute;
    top: 20px;
    left: 20px;
    width: auto;
    background: $brand-primary;
    color: #FFF;
    font-size: 12px;
    padding: 10px;
    line-height: 16px;
  }

  .agency-item-wrapper .popup.frankreich + .popup-item,
  .agency-item-wrapper .popup.japan + .popup-item {
    left: auto;
    right: 16px;
  }

  .agency-item-wrapper .popup.deutschland +.popup-item {
    top: auto;
    bottom: 23px;
  }

  .agency-item.popup + div {
    display: none;
  }

  .agency-item.popup:hover + div {
    display: block;
  }

  .agency-item-wrapper.usa {
    top: 26%;
    left: 13%;
  }

  .agency-item-wrapper.frankreich {
    top: 21%;
    left: 43.75%;
  }

  .agency-item-wrapper.deutschland {
    top: 18%;
    left: 47%;
  }

  .agency-item-wrapper.italien {
    top: 25.35%;
    left: 47.65%;
  }

  .agency-item-wrapper.japan {
    top: 26%;
    left: 85.25%;
  }

  .agency-item.active {
    background-color: $brand-primary;
    color: #FFF;
  }

  .agency-item:hover {
    background-color: $brand-primary;
    color: #FFF;
  }



  #main-nav {
    float: right;
    margin-top: 29px;
    border-bottom: 5px solid $light-grey;
    //padding-left: 10px;
    padding-left: 0;
    margin-bottom: 28px;
    transition: all 0.3s;
  }

  #main-nav .level1 {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  #main-nav .level1 li {
    display: inline-block;
    font-size: 18px;
    // text-transform: uppercase;
    font-weight: 700;
    float: left;
  }

  #main-nav .level1 > li:not(:last-of-type) {
    //margin-right: 40px;
    margin-right: 36px;
  }

  #main-nav a {
    transition: color .2s;
    &.active {
      color: $brand-primary;
    }
  }

  .search-outer {
    position: relative;
  }

  .icon-search {
    color: $brand-primary;
  }

  .search-input {
    display: none;
    position: absolute;
    line-height: 18px;
    padding-left: 10px;
    border: none;
    top: 0;
    text-transform: uppercase;
    outline: none;
    transition: all .7s;
    opacity: 0;
  }

  footer {
    margin-top: 50px;
  }

  .footer-nav {
    position: absolute;
    top: 0;
    right: 15px;
  }

  .copyright {
    margin-bottom: 30px;
  }

  .nav-left {
    background-color: $light-grey;
    padding: 40px 20px 40px 20px;
    margin-bottom: 30px;
    position: relative;
    &:before {
      content:'';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 37px 50px 0;
      border-color: transparent #fff transparent transparent;
      position: absolute;
      right:0;
      top:0;
    }
  }

  .nav-left ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .nav-left a.active {
    color: $brand-primary;
  }

  .nav-left > .level1 > li > a {
    display: block;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .nav-left .level2 > li > a {
    margin-bottom: 15px;
    display: block;
    //margin-left: 15px;
  }

  .nav-left .level3 > li > a {
    margin-bottom: 7px;
    display: block;
  }

  .gallery-slider--item__img-small {
    height: 130px;
  }

  .gallery-slider--item__img-big {
    height: 250px;
  }

}

/* Mittlere Geräte (Desktop-PCs, 992px und breiter) */
@media (min-width: $screen-desktop) {

  .endprodukt {
    max-width: 100%;
  }

  .endprodukt__image {
    height: auto;
    max-height: none;
  }

  .endprodukt__image img {
    transform: none;
    left: 0;
    top: 0;
  }

  .endprodukt__image a {
    height: auto;
    max-height: none;
  }

  .themepic-slider {
    margin-bottom: 60px!important;
  }
  .search-input {
    line-height: 20px;
    //width: 180px;
    //left: -135px;
    width: 100px;
    left: -72px;
  }
  #main-nav {
    margin-top: 51px;
    width: 100%;
  }
  #main-nav .level1 li {
    font-size: 20px;
  }
  #main-nav .level1 > li:not(:last-of-type) {
    margin-right: 50px;
  }
  .icon-home {
    float: left;
    //margin-left: 50px;
  }
  .icon-search {
    margin-left: 10px;
  }
  .search-outer {
    float: right!important;
  }
  .teaser-start__labeling {
    height: 120px;
  }
  .nav-left .level2 > li > a {
    margin-left: 15px;
  }
  .nav-left .level3 > li > a {
    margin-left: 30px;
  }

  .gallery-slider--item__img-big {
    height: 350px;
  }

  .agency-item-wrapper.usa {
    top: 27%;
    left: 13.5%;
  }

  .agency-item-wrapper.frankreich {
    top: 21.5%;
    left: 44.5%;
  }

  .agency-item-wrapper.deutschland {
    top: 19%;
    left: 47.25%;
  }

  .agency-item-wrapper.italien {
    top: 26%;
    left: 48%;
  }

  .agency-item-wrapper.japan {
    top: 26.5%;
    left: 85.5%;
  }
  .infoboxes h1 {
    font-size: 28px;
  }
}

/* Große Geräte (Desktop-PCs, 1200px und breiter) */
@media (min-width: $screen-lg-desktop) {
  .minus {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
  }

  .search-input {
    width: 110px;
    left: -82px;
  }

  .content-col {
    padding-left: 45px;
  }

  #main-nav {
    padding-left: 30px;
  }

  #main-nav .level1 > li:not(:last-of-type) {
    margin-right: 77px;
  }

  .icon-home {
    //margin-left: 80px;
  }

  .teaser-start__labeling {
    height: auto;
  }

  .copyright {
    margin-top: 30px;
  }

  .copyright div {
    margin-right: 20px;
    display: inline-block;
  }

  .copyright div:first-of-type {
    margin-bottom: 0;
  }

  .copyright div:last-of-type {
    margin-top: 0;
  }

  .copyright span {
    display: inline-block;
  }

  .copyright div > span:not(:last-child) {
    &:after {
      content:'-';
      display: inline-block;
      margin: 0 5px;
    };
  }

  footer {
    height: 100px;
  }

  .footer-nav {
    position: relative;
  }

  .nav-outer {
    background-color: #fff;
  }

  .navbar-fixed {
    position: fixed;
    top: 0;
    z-index: 1500;
    width: 100%;
  }

  .navbar-fixed .logo {
    margin-top: 0;
  }

  .navbar-fixed #main-nav {
    margin-top: 64px;
    margin-bottom: 15px;
  }

  //.padding-top {
  //  padding-top: 144px;
  //}

  .padding-top {
    padding-top: 157px;
  }

  //.navbar-fixed ~ .content-outer {
  //  margin-top: 13px;
  //}

  .gallery-slider--item__img-small {
    height: 160px;
  }

  .gallery-slider--item__img-big {
    height: 450px;
  }

  .agency-item-wrapper button {
    width: 24px;
    height: 24px;
  }

  .agency-item-wrapper.usa {
    top: 27%;
    left: 13.5%;
  }

  .agency-item-wrapper.frankreich {
    top: 21.5%;
    left: 44.5%;
  }

  .agency-item-wrapper.deutschland {
    top: 19%;
    left: 47.25%;
  }

  .agency-item-wrapper.italien {
    top: 26%;
    left: 48%;
  }

  .agency-item-wrapper.japan {
    top: 26.5%;
    left: 85.5%;
  }

  .agency-item-wrapper .popup-item {
    top: 20px;
    left: 20px;
  }

  .agency-item-wrapper .popup.frankreich+.popup-item,
  .agency-item-wrapper .popup.japan+.popup-item {
    left: auto;
    right: 20px;
  }

  .agency-item-wrapper .popup.deutschland +.popup-item {
    top: auto;
    bottom: 26px;
  }

}